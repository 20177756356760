import '../styles/collection.css';

window.addEventListener("load", function(){
// document.on("turbolinks:load", function() {

  var boxes = document.getElementsByClassName("toggler-parent");
  var i;
  for (i = 0; i < boxes.length; i++) {
    boxes[i].addEventListener("click", toogleParent);
  }
  boxes = document.getElementsByClassName("toggler-next");
  for (i = 0; i < boxes.length; i++) {
    boxes[i].addEventListener("click", toggleNext);
  }

  function toogleParent() {
    toggleThis(this.parentNode);
  }

  function toggleNext() {
    toggleThis(this.nextElementSibling);
  }

  function toggleThis(element) {
    let classList = element.classList;
    if (classList.contains("collapsed")) {
      classList.remove("collapsed");
      classList.add("open");
    } else {
      classList.remove("open");
      classList.add("collapsed");
    }
  }
});
